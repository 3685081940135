import React from 'react';

import Layout from '../components/Layout';
import {Helmet} from "react-helmet";

export const CodeOfCarePageTemplate = () => (
  <main role="main" className="code-of-care-page">
    <Helmet>
      <title>Code Of Care</title>
    </Helmet>
    {/* Begin Treatment and care */}
    <div className="about-main">
      <div className="inner-wrap">
        <div className="legal text-center" style={{"backgroundImage": `linear-gradient(90deg, rgba(146, 43, 184, 0.69), rgba(246, 31, 128, 0.69) , rgba(254, 164, 120, 0.69)), url(/assets/newimg/1280.png)`}}>
          <h1 className="text-white">Code of Care</h1>
        </div>
        <div className="legal-wrap bg-gray pb-5">
          <div className="container">
            <div className="row legal-row mb-5">
              <div className="col-md-12 p-5 ca-shadow-sm rounded bg-white">
                <h2>The PeteHealth Way</h2><p>
                We exist to serve patients with the highest quality in-home and outpatient physical therapy treatments – whether that is in their homes, their offices or wherever they feel most comfortable.
                We work hand-in-hand with the most respected physicians to ensure a complete and consistent experience.
                Our code of care applies to every one of our patients, and is understood by each member of our staff, and our therapists.
              </p>
                <h2>PeteHealth Values</h2><p>
                We champion a culture of care, believing in the most modern, ethical and professional applications while administering to our patients.
                We believe in these values and are fully committed to preserving them as we carry out our duties.
              </p>
                <h2>Expert Training. Expert Care.</h2><p>
                We know the value of knowledge, training and education and how it makes a difference.
                We ensure that our physical therapist are licensed, bonded, have a minimum work experience standard, and are registered with the legal governing bodies.
              </p>
                <h2>Ensuring Safety</h2><p>
                The safety of our patients feel safe is of the most upmost importance.
                We understand the trust required for patients to have therapists enter the home, or office and have policies in place to prevent or report any unprofessional or unethical behavior by a member of staff or a therapist

              </p>
                <h2>Transparency</h2><p>
                We promote full transparency.
                All our processes, policies and Terms of Service are clearly explained and we encourage questions from our patients that produce detailed and honest answers.

              </p>
                <h2>Last Updated</h2>
                <p>This Code of Care was last updated January 8, 2021.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Treatement and care */}

  </main>
);


const CodeOfCarePage = () => {
  return (
    <Layout>
      <CodeOfCarePageTemplate/>
    </Layout>
  )
};

export default CodeOfCarePage;
